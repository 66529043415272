<!--
--------------------------------------------------------------------------
   ItemListTools.vue
--------------------------------------------------------------------------

    Component to print the item list tools

    Servei de Biblioteques, Publicacions i Arxius (SBPA). UPC.

-->

<template>
    <div class="item-list-tools">
        <ul class="list-inline" v-if="item.type === 'person' || item.type === 'organization'">
            <li class="list-inline-item" v-for="(collaborativeNetwork, index) in collaborativeNetworks" :key="'tool.' + index">
                <a :href="collaborativeNetwork.url" target="_blank" rel="nofollow" :title="collaborativeNetwork.title" class="no-icon-after"><img :src="collaborativeNetwork.image" :alt="collaborativeNetwork.title"></a>&nbsp;<a :href="collaborativeNetwork.url" target="_blank" :title="collaborativeNetwork.title" rel="nofollow">{{ collaborativeNetwork.title }}</a>
            </li>
        </ul>
        <ul class="list-inline" v-if="item.type === 'activity'">
            <!-- Abstract -->
            <li class="list-inline-item" v-if="abstract !== ''">
                <a href="javascript:void(0);" :title="'Read the abstract'|translate" @click="$bvModal.show('item-' + item.id + '-abstract')"><img src="/images/tools/abstract.png" :alt="'Read the abstract'|translate"></a>&nbsp;<a href="javascript:void(0);" @click="$bvModal.show('item-' + item.id + '-abstract')" :title="'Read the abstract'|translate"><translate>Read the abstract</translate></a>
                <b-modal :id="'item-' + item.id + '-abstract'" :title="'Abstract'|translate" ok-only :ok-title="'Close'|translate">
                    <span v-html="abstract"></span>
                </b-modal>
            </li>
            <!-- Full text -->
            <li class="list-inline-item" v-if="fullTextUrl !== ''">
                <a :href="fullTextUrl" target="_blank" rel="nofollow" :title="'Access to the full text'|translate"><img src="/images/tools/fulltext.png" :alt="'Access to the full text'|translate"></a>&nbsp;<a :href="fullTextUrl" target="_blank" :title="'Access to the full text'|translate"><translate>Access to the full text</translate></a>
            </li>
            <!-- View -->
            <li class="list-inline-item" v-if="viewUrl !== '' && fullTextUrl === ''">
                <a :href="viewUrl" target="_blank" rel="nofollow" :title="'View'|translate"><img src="/images/tools/view.png" :alt="'View'|translate"></a>&nbsp;<a :href="viewUrl" target="_blank" :title="'View'|translate" rel="nofollow"><translate>View</translate></a>
            </li>
            <!-- Altmetrics -->
            <li class="list-inline-item no-padding" v-if="doiValue !== ''">
                <div data-badge-type="1" :data-doi="doiValue" data-hide-no-mentions="true" class="altmetric-embed"></div>
            </li>
            <!-- BIBText -->
            <li class="list-inline-item" v-if="bibtex">
                <a href="javascript:void(0);" rel="nofollow" :title="'BIBTex'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'bibtex')"><img src="/images/tools/bibtex.png" :alt="'BIBTex'|translate"></a>&nbsp;<a href="javascript:void(0);" :title="'BIBTex'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'bibtex')"><translate>BIBTex</translate></a>
            </li>
            <!-- RIS -->
            <li class="list-inline-item" v-if="ris">
                <a href="javascript:void(0);" rel="nofollow" :title="'Reference managers'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'RIS')"><img src="/images/tools/ris.png" :alt="'Reference managers'|translate"></a>&nbsp;<a href="javascript:void(0);" :title="'Reference managers'|translate" @click="exportData($store.state.config.URL + '/' + item.id, 'RIS')"><translate>Reference managers</translate></a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: 'item-list-tools',
        props: ['item', 'fields'],
        data() {
            return {
                abstract: '',
                fullTextUrl: '',
                viewUrl: '',
                doiValue: '',
                bibtex: true,
                ris: true,
                collaborativeNetworks: []
            }
        },
        mounted() {
            this.prepareTools();
        },
        methods: {
            /**
             * Prepare item tools to show in profile
             */
            prepareTools() {
                if (this.item.type === 'person' || this.item.type === 'organization') {
                    // Collaborative networks
                    let values = this.getItemFieldValue(this.item, 'collaborativeNetwork', this.$language.current, true);
                    if (values !== null) {
                        values.forEach(v => {
                            v = v.split('##');
                            let tool = {};
                            tool.title = v[1];
                            if (tool.title == '') tool.title = 'Web';
                            tool.url = this.formatURL(v[0]);
                            tool.image = '';
                            this.collaborativeNetworks.push(tool);

                            // Get collaborative network image
                            this.axios.get(this.apiURL + '/collaborativeNetworks/' + tool.title)
                                .then(response => {
                                    tool.image = this.$store.state.config.URL + '/images/' + response.data.image;
                                })
                                .catch(error => {
                                    this.processError(error);
                                });
                        });
                    }
                } else if (this.item.type === 'activity') {
                    // Abstract
                    let values = this.getItemFieldValue(this.item, 'description', this.$language.current, true);
                    if (values !== null) {
                        // Delete duplicates
                        let uniqueValues = new Set(values);
                        values = [...uniqueValues];
                        this.abstract = values.join('<br />');
                    }

                    // Add view full text
                    if (this.item.file !== null && this.item.file.length > 0) this.fullTextUrl = this.item.file[0].split('##')[1];

                    // View and DOI
                    let value = this.getItemFieldValue(this.item, 'doi', this.$language.current, false);
                    if (value !== null) {
                        this.viewUrl = this.$store.state.config.doiURL.replace('{doi}', value);
                        this.doiValue = value;
                    } else {
                        value = this.getItemFieldValue(this.item, 'repositoryUrl', this.$language.current, false);
                        if (value !== null) this.viewUrl = value;
                        else {
                            value = this.getItemFieldValue(this.item, 'url', this.$language.current, false);
                            if (value !== null) this.viewUrl = value;
                        }
                    }
                }
            }
        }
    }
</script>
